/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   Reset.vue of WeTrade
*/
<template>
  <PageWithHeader class="px-32">
    <template #title><t path="resetpass_2">Set Password</t></template>
    <template #right v-if="bindMode">
      <router-link to="/" replace class="px-8 skip c-tip">
        <t path="fbsetpass_3">Skip</t>
      </router-link>
    </template>
    <div class="wedge" style="height: 40px"></div>
    <Form :schema="registerFormSchema" @submit="reset" #="{ values }">
      <TheMobileInput />
      <TheCodeInput @send="cb => sendCode(values.countryCode, values.mobile, cb)" />
      <ThePasswordInput />
      <t path="fbsetpass_9" class="f-xs c-title mt-8">* Password must contain at least 6 characters</t>
      <div class="wedge" style="height: 243px"></div>
      <Button class="primary block f-bold f-lg br-lg" :progress="progress">
        <t path="fbsetpass_10" #="{td}" custom>{{ td || 'Submit' }}</t>
      </Button>
    </Form>
  </PageWithHeader>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import TheCodeInput from '@/pages/register/components/TheCodeInput.vue'
import { registerFormSchema } from '@/pages/register/register'
import useReset, { useBindMobile, useResetCode } from '@/pages/reset/reset'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Form from 'common/form/Form.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Reset',
  components: { Button, ThePasswordInput, TheCodeInput, TheMobileInput, Form, PageWithHeader },
  props: {
    bindMode: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const [requestCode] = useResetCode()
    const [reset, progress] = props.bindMode ? useBindMobile() : useReset()

    return {
      registerFormSchema,
      reset,
      progress,
      sendCode (cc: string, mobile: string, cb: () => void) {
        requestCode(cc, mobile, props.bindMode ? 3 : 2).then(cb)
      },
    }
  },
})
</script>

<style scoped lang="scss">
  .skip {
    height: 24px;
    line-height: 24px;
    border: 1px solid #D5DEE8;
    border-radius: 12px;
  }
</style>
